(() => {

  const properties = {
    contentAttr : "data-step-content",
    linkAttr : "data-step-link",
    contentActive: "forgot-order__block--active",
    linkActive : "forgot-order__nav-link--active"
  };

  const stepWrap = document.querySelector('.forgot-order');
  const stepButton = [...document.querySelectorAll('.forgot-order__nav-link')];
  const stepContent = [...document.querySelectorAll('.forgot-order__block')];
  const stepNav = document.querySelector('.forgot-order__nav');
  const backBtn = document.querySelectorAll('.order-back-btn');
  const submitBtn = document.querySelectorAll('.order-submit-btn');

  let currentStep = 1;
  let lastStep;
  let stepsArr = [];
  let status = "success";

  // status can be :
  // success
  // error

  stepButton.forEach(el => {
    el.addEventListener("click", evt => {
      evt.preventDefault();
      // changeStep(el);
    });

    stepsArr.push(parseInt(el.getAttribute(properties.linkAttr)));
    lastStep = Math.max.apply(null, stepsArr);
  });

  submitBtn.forEach(item => {
    item.addEventListener("click", evt => {
      evt.preventDefault();
      nextStep();
    });
  });

  backBtn.forEach(item => {
    item.addEventListener("click", evt => {
      evt.preventDefault();
      firstStep();
    });
  });

  // const changeStep = element => {
  //   const stepChange = element.getAttribute(properties.linkAttr);
  //   const changeBlock = stepContent.find(el => {
  //     return el.getAttribute(properties.contentAttr) === stepChange;
  //   });

  //   hideAllSteps();

  //   element.classList.add(properties.linkActive);
  //   changeBlock.classList.add(properties.contentActive);

  //   currentStep = Math.trunc(stepChange);
  // };

  const nextStep = () => {

    if ( currentStep < lastStep ) {
      const step = currentStep + 1;
      currentStep += 1;

      const nextStepBtn = stepButton.find(el => {
        return el.getAttribute(properties.linkAttr) === (step + '');
      });
      const nextStepBlock = stepContent.find(el => {
        return el.getAttribute(properties.contentAttr) === (step + '');
      });

      hideAllSteps();

      nextStepBlock.classList.add(properties.contentActive);
      nextStepBtn.classList.add(properties.linkActive);

      console.log(currentStep)

    } else if ( currentStep === lastStep ) {
      statusShow()
    }

  };

  const firstStep = () => {
    const firstStepBtn = stepButton.find(el => {
      return el.getAttribute(properties.linkAttr) === '1';
    });
    const firstStepBlock = stepContent.find(el => {
      return el.getAttribute(properties.contentAttr) === '1';
    });

    currentStep = 1;

    if ( firstStepBtn && firstStepBlock ) {

      hideAllSteps();
      stepNav.style.display = "block";
      firstStepBlock.classList.add(properties.contentActive);
      firstStepBtn.classList.add(properties.linkActive);
    }
  };

  const hideAllSteps = () => {
    stepContent.forEach(item => {
      item.classList.remove(properties.contentActive);
    });
    stepButton.forEach(item => {
      item.classList.remove(properties.linkActive);
    });
  };

  const statusShow = () => {
    hideAllSteps();
    stepNav.style.display = "none";

    const statusStep = stepContent.find(el => {
      return el.getAttribute(properties.contentAttr) === status;
    });


    statusStep.classList.add(properties.contentActive);
  };

})();
