(() => {
  const modalWindows = document.querySelectorAll(".remodal--video");

  modalWindows.forEach(el => {
    const selfEl = $(el);
    const videoEl = el.querySelector(".youtube-video");
    const videoLink = videoEl.getAttribute("data-link");

    const htmlString = `<iframe src="${videoLink}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    // console.log(videoLink);

    $(document).on("closing", selfEl, e => {
      $(videoEl).html("");
    });

    $(document).on("opening", selfEl, e => {
      $(videoEl).html(htmlString);
    });
  });

  // const youtubeVideoWrap = $(".youtube-video");
  // const youtubeVideoLink = youtubeVideoWrap.data("link");

  // console.log(youtubeVideoLink);
  // const videoHTML =
  //   '<iframe src="https://www.youtube.com/embed/LTYp8DubLWA?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
})();
