(() => {
  const categoriesButtons = document.querySelectorAll(`[data-package]`);
  const categoriesContent = document.querySelectorAll(`[data-package-content]`);

  if (categoriesButtons && categoriesContent) {
    categoriesButtons.forEach(elem => {
      elem.addEventListener("click", evt => {
        evt.preventDefault();

        toggleAction(elem);
      });
    });
  }

  const toggleAction = elem => {
    const attr = elem.getAttribute(`data-package`);
    const currentContent = [...categoriesContent].find(elem => {
      return elem.getAttribute(`data-package-content`) === attr;
    });

    if (currentContent) {
      categoriesButtons.forEach(elem => {
        elem.classList.remove(`home-packages-toggle__button--active`);
      });
      elem.classList.add(`home-packages-toggle__button--active`);
      categoriesContent.forEach(elem => {
        elem.setAttribute(`hidden`, `hidden`);
      });
      currentContent.removeAttribute(`hidden`);
    }
  };
})();
