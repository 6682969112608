(() => {
  const homeBgCarousel = $(".home-slider-bg");
  const homeContentCarousel = $(".home-slider-content");
  const homeBgPrev = $(".home-slider-head-prev");
  const homeBgNext = $(".home-slider-head-next");
  const homeHeadDots = $(".home-head-dots-wrap");

  let isDots = false;

  if (homeBgCarousel) {
    const htmlAttr = $("html").attr("dir");

    let settings = {
      items: 1,
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      freeDrag: false,
      dots: false,
      smartSpeed: 1000
    };

    let settingsAnimate = {
      items: 1,
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      freeDrag: false,
      dots: false,
      smartSpeed: 1000,
      // animateOut: "fadeOutSlider",
      animateIn: "fadeInSlider"
    };

    homeBgCarousel.on("translate.owl.carousel", e => {
      let current = e.item.index - 1;
      let item = $(`.new-slider-dot:eq(${current - 1})`);

      thisActive(item[0]);
    });

    homeBgCarousel.on("refreshed.owl.carousel", e => {
      if (e.item.count > 1) {
        $(".home-slider-controls").addClass("home-slider-controls--visible");
      }

      if (!isDots) {
        for (let i = 0; i < e.item.count; i++) {
          const dotItem = document.createElement("div");
          i === 0
            ? (dotItem.className = `new-slider-dot new-slider-dot--active`)
            : (dotItem.className = `new-slider-dot`);

          dotItem.addEventListener("click", evt => {
            evt.preventDefault();
            dotHandle(dotItem, i);
          });

          homeHeadDots.append(dotItem);
        }

        isDots = true;
      }
    });

    const thisActive = elem => {
      const dots = document.querySelectorAll(`.new-slider-dot`);

      dots.forEach(item => {
        item.classList.remove(`new-slider-dot--active`);
      });

      elem.classList.add(`new-slider-dot--active`);
    };

    const dotHandle = (elem, i) => {
      homeBgCarousel.trigger("to.owl.carousel", i);
      homeContentCarousel.trigger("to.owl.carousel", i);
      thisActive(elem);
    };

    if (htmlAttr === "ltr") {
      settings.rtl = false;
      settingsAnimate.rtl = false;
    } else {
      settings.rtl = true;
      settingsAnimate.rtl = true;
    }

    homeBgCarousel.owlCarousel(settings);
    homeContentCarousel.owlCarousel(settingsAnimate);

    homeBgPrev.click(evt => {
      evt.preventDefault();

      homeBgCarousel.trigger("prev.owl.carousel");
      homeContentCarousel.trigger("prev.owl.carousel");
    });

    homeBgNext.click(evt => {
      evt.preventDefault();

      homeBgCarousel.trigger("next.owl.carousel");
      homeContentCarousel.trigger("next.owl.carousel");
    });
  }
})();
