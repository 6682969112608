(() => {

  const header = document.querySelector('.main-header');
  const headerNav = header.querySelector('.main-header__nav');
  const hamburger = document.querySelector('.hamburger-btn');

  const mobileOwl = $('.mobile-owl .owl-carousel');
  const mobileOwlPrev = $('.mobile-owl__btn--left');
  const mobileOwlNext = $('.mobile-owl__btn--right');

  let mobOwlSettings = {
    items: 1,
    margin: 0,
    loop: true,
    nav: false,
    dots: false,
    rtl: false,
    center: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    freeDrag: false
  };

  mobileOwlPrev.on("click", evt => {
    evt.preventDefault();
    mobileOwl.trigger('prev.owl.carousel');
  });

  mobileOwlNext.on("click", evt => {
    evt.preventDefault();
    mobileOwl.trigger('next.owl.carousel');
  });

  const mobOwlInit = () => {
    if ( $(window).width() < 768 ) {
      const htmlAttr = $('html').attr('dir');

      if ( htmlAttr === 'ltr' ) {
        mobOwlSettings.rtl = false;
        mobileOwl.owlCarousel(mobOwlSettings);
      } else {
        mobOwlSettings.rtl = true;
        mobileOwl.owlCarousel(mobOwlSettings);
      }
      // if ( )
      mobileOwl.owlCarousel(mobOwlSettings);
    } else {
      mobileOwl.trigger('destroy.owl.carousel');
    }
  }

  $(window).resize(function() {
    mobOwlInit();
  });

  $(window).on("load", function() {
    mobOwlInit();
  });

  if ( typeof AOS !== 'undefined' ) {
    AOS.init();
  };

  hamburger.addEventListener('click', ev => {
    ev.preventDefault();

    hamburger.classList.toggle('hamburger-btn--active');
    headerNav.classList.toggle('main-header__nav--open');
    header.classList.toggle('main-header--open')
  });


  window.addEventListener('scroll', ev => {
    updateHeaderOpacity();
  });

  window.onload = () => {
    updateHeaderOpacity();
  };

  const updateHeaderOpacity = () => {

    const scrollTempVal = Math.trunc(window.pageYOffset / 700 * 10);

    header.style.backgroundColor = `rgba(0, 17, 54, ${parseFloat((window.pageYOffset / 700).toFixed(3))})`;

    if ( scrollTempVal > 4 ) {
      header.classList.add('main-header--scrolled')
    } else {
      header.classList.remove('main-header--scrolled')
    }
  };

})();
