(() => {
  const filterItems = [...document.querySelectorAll('[data-filter]')];
  const filterButtons = document.querySelectorAll('[data-filter-btn]');

  let pageSize;

  let owlOptions = {
    loop: false,
    rtl: false,
    nav: false,
    margin: 20,
    mouseDrag: false,
    dots: false,
    responsive: {
      0 : {
        items: 2
      },
      768 : {
        items: 3
      },
      992 : {
        items: 4
      }
    },
    onResized: function(evt) {
      pageSize = evt.page.size;
    },
    onInitialized: function(evt) {
      pageSize = evt.page.size;
      const items = evt.item.count;
      const element = evt.target;
      const elementParent = element.offsetParent;
      const prevButton = elementParent.querySelector('.carousel-btn--left');
      const nextButton = elementParent.querySelector('.carousel-btn--right');

      if ( items > pageSize ) {
        nextButton.style.display = "block";
      } else {
        nextButton.style.display = "none";
      }

    },
    onChange: function(evt) {
      const pos = evt.property.value;
      const element = evt.target;
      const elementParent = element.offsetParent;
      const prevButton = elementParent.querySelector('.carousel-btn--left');
      const nextButton = elementParent.querySelector('.carousel-btn--right');

      if ( pos > 0 ) {
        prevButton.style.display = "block";
      } else {
        prevButton.style.display = "none";
      };

      if ( evt.item.count - pos === pageSize) {
        nextButton.style.display = "none";
      } else {
        nextButton.style.display = "block";
      };
    }
  };

  if ( $('.owl-carousel') ) {
    const htmlAttr = $('html').attr('dir');

    if ( htmlAttr === 'ltr' ) {
      owlOptions.rtl = false;
    } else {
      owlOptions.rtl = true;
    };

    $('.carousel-wrap .owl-carousel').owlCarousel(owlOptions);
  };


  $('.carousel-btn--right').on("click", function(evt) {
    const carousel = $(this).parents('.carousel-wrap').find('.owl-carousel');
    evt.preventDefault();

    carousel.trigger('next.owl.carousel');
  });

  $('.carousel-btn--left').on("click", function(evt) {
    const carousel = $(this).parents('.carousel-wrap').find('.owl-carousel');
    evt.preventDefault();

    carousel.trigger('prev.owl.carousel');
  });

  filterButtons.forEach(btn => {
    btn.addEventListener('click', evt => {
      evt.preventDefault();
      filterContent(btn);
    });
  });

  const filterContent = elem => {
    const elemVal = elem.getAttribute('data-filter-btn');

    filterItems.forEach(item => {
      if ( item.getAttribute('data-filter') === elemVal ) {
        // $('.owl-carousel').destroy();
        // item.classList.add('visible');
        // item.style.display = 'block';
        item.style.opacity = 1;
        item.style.marginBottom = "5rem";
        item.style.height = "auto";
      } else {
        // item.style.display = 'none'
        item.style.opacity = 0;
        item.style.marginBottom = 0;
        item.style.height = 0;
        // item.classList.remove('visible');
      }
    });

    filterButtons.forEach(item => {
      item.classList.remove('plan-filter__link--active');
    });

    elem.classList.add('plan-filter__link--active');
  };
})();
