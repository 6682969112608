(() => {

  const rechargeTabButtons = document.querySelectorAll('.recharge-tab__btn');
  const rechargeTabContents = [...document.querySelectorAll('.rechange-content')];

  // const vaucherInput = document.querySelector('.input-vaucher');
  // const amountInput = document.querySelector('.input-amount');
  // const phoneInput = [...document.querySelectorAll('.input-phone')];

  const termsWindowBtn = document.querySelector('.terms-window-btn');
  const termsWindow = document.querySelector('.terms-modal-window');
  const termsWindowClose = document.querySelectorAll('[data-terms="close"]');

  // console.log(phoneInput)

  if ( termsWindowBtn ) {
    termsWindowBtn.addEventListener('click', evt => {
      evt.preventDefault();
      termsWindow.style.display = 'flex';
    });
  }

  termsWindowClose.forEach(item => {
    item.addEventListener('click', evt => {
      evt.preventDefault();
      termsWindow.style.display = 'none';
    });
  });

  // const maskVaucherOptions = {
  //   mask: '0000-0000-0000-0000'
  // };

  // const maskPhoneOptions = {
  //   mask: '+{89\\0} 000 0000 0000'
  // };

  // const maskAmountOptions = {
  //   mask: Number,
  //   min: 0.50,
  //   max: 20,
  // };

  // if ( phoneInput ) {
  //   phoneInput.forEach(item => {
  //     new IMask(item, maskPhoneOptions);
  //   });
  // }

  // if ( vaucherInput ) {
  //   new IMask(vaucherInput, maskVaucherOptions);
  // };

  // if ( amountInput ) {
  //   new IMask(amountInput, maskAmountOptions);
  // };


  rechargeTabButtons.forEach( btn => {
    btn.addEventListener('click', event => {
      event.preventDefault();
      buttonSwitch(btn);
    });
  });

  const buttonSwitch = el => {
    const tabValue = el.getAttribute('data-content-tab');
    const activeTab = rechargeTabContents.find( el => {
      return el.getAttribute('data-content') === tabValue;
    });

    rechargeTabButtons.forEach( btn => {
      btn.classList.remove('recharge-tab__btn--active');
    });

    el.classList.add('recharge-tab__btn--active');
    changeTab(activeTab);
  };

  const changeTab = el => {
    rechargeTabContents.forEach( tab => {
      tab.classList.remove('rechange-content--visible');
    });

    el.classList.add('rechange-content--visible');
  };

})();
