(() => {
  const mobileCarouselWrap = $(".mobile-carousel");
  const mobileDotsWrap = $(".mobile-carousel-dots");

  if (mobileCarouselWrap) {
    const htmlAttr = $("html").attr("dir");

    let settings = {
      items: 1,
      loop: true,
      autoplay : true,
      autoplayTimeout:7000,
      autoplaySpeed: 1000,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      freeDrag: false,
      nav: false,
      dots: true,
      dotsContainer: mobileDotsWrap,
      smartSpeed: 400
    };

    if (htmlAttr === "ltr") {
      settings.rtl = false;
    } else {
      settings.rtl = true;
    }

    /*mobileCarouselWrap.on("refreshed.owl.carousel", e => {
      if (e.item.count > 1) {
        mobileDotsWrap.addClass("mobile-carousel-dots--visible");
        // console.log(e.item.count);
      }
    });*/

    mobileCarouselWrap.owlCarousel(settings);
  }
})();
