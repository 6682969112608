(() => {
  const defaultCarousel = document.querySelectorAll(".default-carousel");

  if (defaultCarousel) {
    const htmlAttr = document.documentElement.getAttribute("dir");

    console.log(htmlAttr);

    defaultCarousel.forEach(elem => {
      let settings = {
        items: 3,
        margin: 50,
        loop: false,
        mouseDrag: false,
        touchDrag: true,
        pullDrag: false,
        freeDrag: false,
        dots: true,
        smartSpeed: 400,
        responsive: {
          0: {
            items: 1,
            margin: 50
          },
          767: {
            items: 2,
            margin: 10
          },
          1024: {
            items: 3,
            margin: 10
          }
        }
      };

      let pageSize,
        itemsCount = 0;

      let carouselHandle = elem.querySelector(".default-carousel__handle");
      let carouselDotsWrap = elem.querySelector(".default-carousel__dots");
      let carouselPrev = elem.querySelector(".default-slider--prev");
      let carouselNext = elem.querySelector(".default-slider--next");
      let carouselItems = [
        ...elem.querySelectorAll(".default-carousel__handle-item")
      ];

      if (carouselItems.length < 3) {
        settings.items = carouselItems.length;
      }

      settings.dotsContainer = carouselDotsWrap;

      carouselHandle = $(carouselHandle);
      carouselDotsWrap = $(carouselDotsWrap);
      carouselPrev = $(carouselPrev);
      carouselNext = $(carouselNext);

      const checkArrow = index => {
        if (index + pageSize === itemsCount) {
          carouselNext.hide();
        } else {
          carouselNext.show();
        }

        if (index === 0) {
          carouselPrev.hide();
        } else {
          carouselPrev.show();
        }
      };

      carouselHandle.on("refreshed.owl.carousel", e => {
        pageSize = e.page.size;
        itemsCount = e.item.count;

        if (e.page.size >= e.item.count) {
          carouselPrev.css(`visibility`, `hidden`);
          carouselNext.css(`visibility`, `hidden`);
          carouselDotsWrap.css(`visibility`, `hidden`);
        } else {
          carouselPrev.css(`visibility`, `visible`);
          carouselNext.css(`visibility`, `visible`);
          carouselDotsWrap.css(`visibility`, `visible`);
        }

        checkArrow(e.item.index);
      });

      if (htmlAttr === "ltr") {
        settings.rtl = false;
      } else {
        settings.rtl = true;
      }

      carouselHandle.owlCarousel(settings);

      carouselHandle.on("translate.owl.carousel", event => {
        checkArrow(event.item.index);
      });

      carouselHandle.on("translated.owl.carousel", event => {
        checkArrow(event.item.index);
      });

      carouselPrev.click(evt => {
        evt.preventDefault();
        carouselHandle.trigger("prev.owl.carousel");
      });

      carouselNext.click(evt => {
        evt.preventDefault();
        carouselHandle.trigger("next.owl.carousel");
      });
    });
  }
})();
